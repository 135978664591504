import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import {
	ProductOverviewContainerProps,
	ProductOverviewAnchorPointProps,
	ProductOverviewVideoProps,
	ProductOverviewMediaWrapperProps,
} from './types';

const ProductOverviewBlock = ({ children }: PropsWithChildren<unknown>) => (
	<div className="product-overview">
		{children}
	</div>
);

const ProductOverviewContainer = ({ children, noNavigation }: PropsWithChildren<ProductOverviewContainerProps>) => (
	<div className={clsx('product-overview__container', {	
		'product-overview__container--no-navigation': noNavigation,
	})}>
		{children}
	</div>
);

const ProductOverviewAnchorPoint = ({ children, top, left }: PropsWithChildren<ProductOverviewAnchorPointProps>) => (
	<div className="product-overview__anchor-point" style={{ top, left }}>
		{children}
	</div>
);

const ProductOverviewMediaWrapper = ({ children, showClipPath }: PropsWithChildren<ProductOverviewMediaWrapperProps>) => (
	<div className={clsx('product-overview__media-wrapper', {
		'product-overview__media-wrapper--show-clippath': showClipPath,
	})}>
		{children}
	</div>
);

const ProductOverviewVideo = ({
	videoSrc,
	poster,
	autoPlay,
	loop,
	muted,
	playsInline,
	noNavigation,
}: ProductOverviewVideoProps) => (
	<video
		autoPlay={autoPlay}
		loop={loop}
		muted={muted}
		playsInline={playsInline}
		poster={poster}
		className={clsx('product-overview__video', {
			'product-overview__video--has-navigation': !noNavigation,
		})}
	>
		<source src={videoSrc} type="video/mp4" />
	</video>
);

export {
	ProductOverviewBlock,
	ProductOverviewContainer,
	ProductOverviewAnchorPoint,
	ProductOverviewVideo,
	ProductOverviewMediaWrapper,
};
