import React, { useState, useEffect, useRef, useMemo } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import {
	showCartToggleVar,
	showMenuToggleVar,
} from '../../apollo/cache';
import {
	usePrevious,
	useWindowSize,
} from '../../hooks';
import Layout from '../../components/layout';
import Meta from '../../components/meta';
import { InternalLinkButton } from '../../components/button';
import { GridContainer, GridBlock, GridSpacing } from '../../components/grid';
import {
	ProductOverviewBlock,
	ProductOverviewContainer,
	ProductOverviewAnchorPoint,
	ProductOverviewVideo,
	ProductOverviewMediaWrapper,
} from '../../components/product-overview';
import {
	A2NavigationBlock,
	A2NavigationButton,
	A2NavigationCount,
	A2NavigationRuler,
	A2NavigationArrowShape,
} from '../../components/a2-navigation';

gsap.registerPlugin(SplitText);

const CollectionTemplate = ({pageContext, path}) => {
	const {
		content,
		slug,
	} = pageContext;
	const {
		collection_title,
		collection_subtitle,
		background_color,
		meta_title,
		featured_image,
		meta_description,
		meta_image,
		fullscreen_video,
		products,
	} = content;
	const introTimeline = useRef<GSAPTimeline>();
	const rootRef = useRef<HTMLDivElement>();
	const productOverviewRef = useRef<HTMLDivElement>();
	const navigationRef = useRef<HTMLDivElement>();
	const pageTitlesRef = useRef<HTMLDivElement>();
	const [currentProductIndex, setCurrentProductIndex] = useState(0);
	const [currentImagePosition, setCurrentImagePosition] = useState(0);
	const previousIndex = usePrevious(currentProductIndex);
	const imagePosition = { val: 0 };

	const { width, height } = useWindowSize();

	const isWindowBelowLarge = useMemo(() => {
		/* 
		* Check window ratio if its below 1.27, then the 
		* product overview will be cut of and not all links are clickable. 
		* If thats the case, we use A2 navigation.
		*/
		return 1.35 > (width / height);
	}, [width, height]);


	const getImagePosition = (productIndex: number): number => {
		const [product] = products.filter((_, index) => productIndex === index);
		return product ? product.viewport_focus_point : 50;
	};

	const moveProductContainer = () => {
		const productOverview = gsap.utils.selector(productOverviewRef);
		const fullscreenVideo = productOverview('.product-overview__video');
		const collectionContainer = productOverview('.product-overview__container');
		const tween = {
			'--viewport-focus-point': isWindowBelowLarge ? getImagePosition(currentProductIndex) : 50,
		};

		if (fullscreenVideo) {
			gsap.set(fullscreenVideo, tween);
		}
		gsap.set(collectionContainer, tween);
	};

	const handleImagePosition = () => {
		const previousPosition = getImagePosition(previousIndex);
		const nextPosition = getImagePosition(currentProductIndex);
		imagePosition.val = previousPosition;

		gsap.to(imagePosition, {
			val: nextPosition,
			duration: .2,
			ease: 'power1.out',
			modifiers: {
				val: val => val.toFixed(0),
			},
			onUpdate: () => {
				setCurrentImagePosition(imagePosition.val);
			},
		});
	};

	const createIntroAnimation = () => {
		introTimeline.current = gsap.timeline();
		const rootBlock = gsap.utils.selector(rootRef);
		const navigation = gsap.utils.selector(navigationRef);
		const productOverview = gsap.utils.selector(productOverviewRef);
		const pageTitles = gsap.utils.selector(pageTitlesRef);
		const titleText = new SplitText(pageTitles('#title'), { type: 'lines' });

		introTimeline.current
			.from([
				navigation('.a2-navigation'),
				rootBlock('.header__menu-toggle'),
				rootBlock('.header__actions-wrapper'),
			], {
				autoAlpha: 0,
				delay: .5,
				duration: .5,
			}, 0)
			.from(productOverview('.product-overview__anchor-point'), {
				'--anchor-position-x': -100,
				duration: 1,
				stagger: index => {
					if (index === 0) return .35;
					if (index === 1) return .7;
					return 0;
				},
			}, 0)
			.from(productOverview('.button'), {
				duration: 1,
				opacity: 0,
				stagger: index => {
					if (index === 0) return .35;
					if (index === 1) return .7;
					return 0;
				},
			}, 0)
			.from(titleText.lines, {
				duration: 1,
				yPercent: 100,
			}, 1)
			.from(pageTitles('#subtitle'), {
				opacity: 0,
				duration: 1,
			}, 2)
			.set('body', {
				backgroundColor: background_color ? background_color : '#fff',
			}, 2)
			.pause();
	};

	useEffect(() => {
		handleImagePosition();
		moveProductContainer();
	}, [currentProductIndex, isWindowBelowLarge]);

	useEffect(() => {
		showMenuToggleVar(true);
		showCartToggleVar(true);
		createIntroAnimation();
		introTimeline.current.play();
	}, []);

	return (
		<div ref={rootRef}>
			<Layout lockedToViewport path={path}>
				<>
					<Meta
						language="en"
						title={meta_title}
						description={meta_description}
						ogImage={meta_image?.filename}
						hrefLangEn={`https://eu.iconsbymenu.com/${slug}`}
						hrefLangEnUs={`https://us.iconsbymenu.com/${slug}`}
						hrefLangUrlXDefault={`https://eu.iconsbymenu.com/${slug}`}
					/>
					<div>
						<div ref={pageTitlesRef} className="absolute w-full z-1">
							<GridSpacing>
								<GridContainer>
									<GridBlock>
										<div className="col-span-full md:col-span-7 md:col-start-2 md:col-end-9">
											<div className="text-white uppercase pt-126 short:pt-48 font-sans-serif tracking-22">
												<span className="text-10 leading-210" id="subtitle">{collection_subtitle}</span>
												<h1 className="overflow-hidden lg:pl-24 text-42 md:text-55" id="title">{collection_title}</h1>
											</div>
										</div>
									</GridBlock>
								</GridContainer>
							</GridSpacing>
						</div>
						<div ref={productOverviewRef}>
							<ProductOverviewBlock>
								{(featured_image?.filename && !fullscreen_video?.filename) && (
									<ProductOverviewMediaWrapper showClipPath={false}>
										<img
											src={featured_image.filename}
											alt={featured_image.alt}
											className='absolute object-cover w-full h-full -z-1'
											style={{ objectPosition: isWindowBelowLarge ? `${currentImagePosition}% 50%` : '50% 50%' }}
										/>
									</ProductOverviewMediaWrapper>
								)}
								{fullscreen_video?.filename && (
									<ProductOverviewMediaWrapper showClipPath={false}>
										<ProductOverviewVideo
											loop
											muted
											autoPlay
											playsInline
											videoSrc={fullscreen_video.filename}
											poster={featured_image?.filename}
											noNavigation={!isWindowBelowLarge}
										/>
									</ProductOverviewMediaWrapper>
								)}
								<ProductOverviewContainer noNavigation={!isWindowBelowLarge}>
									{products && products.map((item, index) => (
										<ProductOverviewAnchorPoint
											top={`${item.link_position_y_axis}%`}
											left={`${item.link_position_x_axis}%`}
											key={index}
										>
											<InternalLinkButton showCurtainAnimation noHoverEffect noBorder to={`/${item.product_link.cached_url}`}>
												{item.product_title}
											</InternalLinkButton>
										</ProductOverviewAnchorPoint>
									))}
								</ProductOverviewContainer>
							</ProductOverviewBlock>
						</div>
						<div ref={navigationRef}>
							{isWindowBelowLarge && <A2NavigationBlock dark>
								<A2NavigationButton
									screenReaderText='Show previous product'
									disabled={currentProductIndex === 0}
									onClick={() => setCurrentProductIndex(currentProductIndex - 1)}
								>
									<A2NavigationArrowShape mirrored />
								</A2NavigationButton>
								<A2NavigationCount count={currentProductIndex + 1} />
								<A2NavigationRuler />
								<A2NavigationCount count={products ? products.length : 0} />
								<A2NavigationButton
									screenReaderText='Show next product'
									disabled={products ? currentProductIndex === products.length - 1 : true}
									onClick={() => setCurrentProductIndex(currentProductIndex + 1)}
								>
									<A2NavigationArrowShape />
								</A2NavigationButton>
							</A2NavigationBlock>}
						</div>
					</div>
				</>
			</Layout>
		</div>
	);
};

export default CollectionTemplate;

